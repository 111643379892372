export const getCaption = (data: object) => {
  const nuxtApp = useNuxtApp()
  const { $i18n } = nuxtApp
  const { locale, defaultLocale } = $i18n

  const formatCaption = (caption: string) => {
    if (!caption) return null
    const lastChar = caption?.trim()[caption?.length - 1]
    return lastChar?.match((/[a-zA-Z0-9]/)) !== null ? `${caption?.trim()}.` : caption?.trim()
  }

  if (data?.localized_caption) {
    return formatCaption(data.localized_caption[locale.value] || data.localized_caption['en-US'])
  } else if (data?.caption) {
    return formatCaption(data.caption)
  }
}
